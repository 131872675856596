window.enquiryPopup = function(page) {
  $('#enquiryForm h2 .text-success').text(page == 'Homepage' ? 'Business Loan' : page)
  $('#enquiry_page').val(page)
  $('#enquiryForm').modal('show')
}

window.submitEnquiry = function(event) {
  var checkbox = document.getElementById("enquiry_not_a_robot");
  var errorMessage = document.getElementById("checkbox-error");

  if (!checkbox.checked) {
    event.preventDefault();
    errorMessage.textContent = "You must confirm you're not a robot.";
    errorMessage.style.color = "red";
    errorMessage.style.fontSize = "14px";
  } else {
    errorMessage.textContent = "";
  }
}

window.scrollToEl = function(el) {
  smoothScroll($(el)[0], {
    offset: 100,
    getEasingFunction: function(targetPosition) {
      return function(stepCount) {
        return stepCount * 1;
      };
    }
  });
}

$(document).on('turbolinks:load', function() {
  console.log('abc1');
})
